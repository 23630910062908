
import { ReactElement, RefObject, createContext, useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Http } from "../../common/http";
import { ApiPath } from "../../common/http/api-path";
import { MetaModel } from "../../model/base-response";
import { BrandModel } from "../../model/brand-model";
import { ProductFilterModel } from "../../model/product-filter-model";
import { ProductModel } from "../../model/product-model";
import { SourceModel } from "../../model/source-model";
import { StoreModel } from "../../model/store-model";
import { SortBy, TableCellModel } from "../../model/table-cell-model";
import { useRootProvider } from "../../provider/root/provider";

const ProductListContext = createContext<ProductListType>(null!)

interface ProductListType {
    brands: Array<BrandModel>
    setBrands: (brands: Array<BrandModel>) => void
    stores: Array<StoreModel>
    setStore: (stores: Array<StoreModel>) => void
    sources: Array<SourceModel>
    setSources: (sources: Array<SourceModel>) => void
    products: Array<ProductModel>
    setProducts: (products: Array<ProductModel>) => void
    meta: MetaModel | undefined
    setMeta: (meta: MetaModel | undefined) => void
    filter: ProductFilterModel | undefined
    setFilter: (filter: ProductFilterModel | undefined) => void
    open: boolean
    setOpen: (open: boolean) => void
    openPrice: boolean
    setOpenPrice: (openPrice: boolean) => void
    cells: Array<TableCellModel>
    setCells: (cells: Array<TableCellModel>) => void
    searchRef: RefObject<HTMLInputElement | undefined>
    priceRef: RefObject<HTMLInputElement | undefined>
    upDownRef: RefObject<HTMLInputElement | undefined>
    nameRef: RefObject<HTMLInputElement | undefined>
    categoryRef: RefObject<HTMLInputElement | undefined>
    getBrands: () => any
    getStores: () => any
    getSource: () => any
    getProducts: () => any
    updateProduct: (product: ProductModel) => any
    sortProducts: (data: Array<ProductModel>) => any
    init: () => any
}

export function useProductListProvider() {
	return useContext(ProductListContext)
}


export function ProductListProvider({ children }: { children: ReactElement }) {
	const [brands, setBrands] = useState<Array<BrandModel>>([])
	const [stores, setStore] = useState<Array<StoreModel>>([])
	const [sources, setSources] = useState<Array<SourceModel>>([])
	const [products, setProducts] = useState<Array<ProductModel>>([])
	const [meta, setMeta] = useState<MetaModel | undefined>()
	const [filter, setFilter] = useState<ProductFilterModel | undefined>({})
	const [open, setOpen] = useState<boolean>(false)
	const [openPrice, setOpenPrice] = useState<boolean>(false)
	const [cells, setCells] = useState<Array<TableCellModel>>([
		TableCellModel.fromJson({ id: 0, name: 'Select', isShow: true }),
		TableCellModel.fromJson({ id: 1, name: 'No', isShow: true }),
		TableCellModel.fromJson({ id: 2, name: 'Name', isShow: true }),
		TableCellModel.fromJson({ id: 3, name: 'Price', isShow: true }),
		TableCellModel.fromJson({ id: 4, name: 'Category', isShow: false }),
		TableCellModel.fromJson({ id: 5, name: 'Status', isShow: false }),
		TableCellModel.fromJson({ id: 6, name: 'Store', isShow: true }),
		TableCellModel.fromJson({ id: 7, name: 'Source', isShow: false }),
		TableCellModel.fromJson({ id: 8, name: 'Brand', isShow: false }),
	])
	const searchRef = useRef<HTMLInputElement | undefined>()
	const priceRef = useRef<HTMLInputElement | undefined>()
	const upDownRef = useRef<HTMLInputElement | undefined>()
	const nameRef = useRef<HTMLInputElement | undefined>()
	const categoryRef = useRef<HTMLInputElement | undefined>()

	const [search] = useSearchParams()
	const brandId = search.get('brand')
	const storeId = search.get('store')
	const sourceId = search.get('source')
	const page = search.get('page')
	const limit = search.get('limit')
	const searchText = search.get('search')
	const category = search.get('category')
	const dataCells = search.get('cells')
	const sortBy = search.get('sortBy')

	const rootProvider = useRootProvider()


	async function getBrands() {
		const result = await Http.instance<Array<BrandModel>>()
			.setPath(ApiPath.brands)
			.onFromJson((json) => json.map((it: any) => BrandModel.fromJson(it)))
			.get()
		if (!result.isOke()) {
			return rootProvider.setMsg(result.error?.message)
		}
		setBrands(result.data ?? [])
		filter!.brand = result.data?.find((it) => it._id === brandId)
		setFilter({ ...filter })
	}

	async function getStores() {
		const result = await Http.instance<Array<StoreModel>>()
			.setPath(ApiPath.stores)
			.setQuery({
				brandId: brandId,
				page: 1,
				limit: 200,
			})
			.onFromJson((json) => json.map((it: any) => StoreModel.fromJson(it)))
			.get()
		if (!result.isOke()) {
			return rootProvider.setMsg(result.error?.message)
		}
		setStore(result.data ?? [])
		filter!.store = result.data?.find((it) => it._id === storeId)
		setFilter({
			...filter,
		})
	}

	async function getSource() {
		const result = await Http.instance<Array<SourceModel>>()
			.setPath(ApiPath.sources)
			.setQuery({
				store: storeId,
				page: 1,
				limit: 200,
			})
			.onFromJson((json) => json.map((it: any) => SourceModel.fromJson(it)))
			.get()
		if (!result.isOke()) {
			return rootProvider.setMsg(result.error?.message)
		}
		setSources(result.data ?? [])
		filter!.source = result.data?.find((it) => it._id === sourceId)
		setFilter({
			...filter,
		})
	}
	async function getProducts() {
		const result = await Http.instance<Array<ProductModel>>()
			.setPath(ApiPath.products)
			.setQuery({
				brand: brandId ?? '',
				store: storeId ?? '',
				source: sourceId ?? '',
				search: searchText ?? '',
				page: page ?? 1,
				limit: limit ?? 100,
			})
			.onFromJson((json) => json.map((it: any) => ProductModel.fromJson(it)))
			.get()
		if (!result.isOke()) {
			return rootProvider.setMsg(result.error?.message)
		}
		const data = result.data?.filter((e) => e.displayCategory().toLowerCase().includes(category?.toLowerCase() ?? ''))
		sortProducts(data ?? [])
		setMeta(result.meta)
	}

	async function updateProduct(product: ProductModel) {

		const rawProduct = {
			...product,
			brandId: product.brand?._id,
			price: product.price,
			categoryIds: product.categories?.map((e) => e._id),
			optionIds: product.options?.map((e) => e._id),
			sourceId: product.source?._id,
			storeId: product.store?._id
		}
		const result = await Http.instance()
			.setPath(ApiPath.updateMenuItem)
			.setParams({
				productId: product._id
			})
			.setBody(rawProduct)
			.put()
		if (result.isOke()) {
			setProducts(
				products.map((it) => it)
			)
			setOpenPrice(false)
		}

	}

	function sortProducts(data: Array<ProductModel>) {
		if (!sortBy) {
			setProducts(data)
			return
		}

		const splits = sortBy?.split('-')
		const cellId = +splits.at(0)!
		const sortType = +splits.at(1)!
		var iProducts: Array<ProductModel> = []

		if (sortType === SortBy.asc) {
			if (cellId === 2) {
				iProducts = data.sort((a, b) => a.name! >= b.name! ? 1 : -1).map((it) => it)
			} else if (cellId === 3) {
				iProducts = data.sort((a, b) => a.price! >= b.price! ? 1 : -1).map((it) => it)
			}  else if (cellId === 4) {
				iProducts = data.sort((a, b) => a.displayCategory() >= b.displayCategory() ? 1 : -1).map((it) => it)
			} else if (cellId === 5) {
				iProducts = data.sort((a, b) => a.status! >= b.status! ? 1 : -1).map((it) => it)
			}
		} else {
			if (cellId === 2) {
				iProducts = data.sort((a, b) => a.name! < b.name! ? 1 : -1).map((it) => it)
			} else if (cellId === 3) {
				iProducts = data.sort((a, b) => a.price! < b.price! ? 1 : -1).map((it) => it)
			} else if (cellId === 4) {
				iProducts = data.sort((a, b) => a.displayCategory() < b.displayCategory() ? 1 : -1).map((it) => it)
			} else if (cellId === 5) {
				iProducts = data.sort((a, b) => a.status! < b.status! ? 1 : -1).map((it) => it)
			}
		}

		setProducts(iProducts)
	}

	async function init() {
		await getBrands()
		if (brandId) {
			await getStores()
			if (sourceId) {
				getSource()
			}
		}
	}

	useEffect(() => {
		if (!sortBy) return
		sortProducts(products)
	}, [sortBy])


	useEffect(() => {
		if (!dataCells) return
		const splits = dataCells?.split('-')
		for (const it of cells) {
			it.isShow = splits?.includes(it.id?.toString() ?? '')
		}
		setCells(cells.map((it) => it))
	}, [dataCells])


	useEffect(() => {
		init()
	}, [])

	useEffect(() => {
		if (!brandId || !brands.length) return
		getStores()
	}, [brandId])

	useEffect(() => {
		if (!storeId || !stores.length) return
		getSource()
	}, [storeId])

	useEffect(() => {
		if (!brandId) return
		getProducts()
	}, [sourceId, storeId, brandId, searchText, page, limit,category])

    const value = {
        brands,
        setBrands,
        stores,
        setStore,
        sources,
        setSources,
        products,
        setProducts,
        meta,
        setMeta,
        filter,
        setFilter,
        open,
        setOpen,
        openPrice,
        setOpenPrice,
        cells,
        setCells,
        searchRef,
        priceRef,
        upDownRef,
        nameRef,
        categoryRef,
        getBrands,
        getStores,
        getSource,
        getProducts,
        updateProduct,
        sortProducts,
        init,
    }
	return (
		<ProductListContext.Provider value={value}>
			{children}
		</ProductListContext.Provider>
	)
}
