import { Box, Button, Container, Modal, Stack, TextField } from "@mui/material";
import { useProductListProvider } from "../provider";

interface ModalUpdateType {

}

const ModalUpdate = (props: ModalUpdateType) => {
    const provider = useProductListProvider()

    function isOneProduct() {
        return provider.products.filter((it) => it.isSelected === true).length === 1
    }
    function getProduct() {
        return provider.products.filter((it) => it.isSelected === true).at(0)
    }

    async function _onOke(): Promise<void> {
        const value = provider.priceRef.current?.value
        if (!value) {
            return
        }
        const upDownValue = provider.upDownRef.current?.value
        const selectedProducts = provider.products.filter((it) => it.isSelected === true)
        for (const it of selectedProducts) {
            if (upDownValue && +upDownValue) {
                it.price ??= 0
                it.price += +upDownValue
            } else {
                it.price = +value
            }

            if (isOneProduct()) {
                it.name = provider.nameRef.current?.value
            }
            await provider.updateProduct(it)
        }
    }

    return (
        <Modal
            open={provider.openPrice}
            onClose={() => provider.setOpenPrice(false)}
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Container
                sx={{
                    backgroundColor: 'white',
                    maxWidth: 400,
                    borderRadius: 4,
                    p: 2,
                }}>
                {
                    isOneProduct() &&
                    <Box>
                        <TextField
                            type="text"
                            fullWidth
                            inputRef={provider.nameRef}
                            defaultValue={getProduct()?.name}
                            placeholder="New name"
                        />
                        <Box height={12} />
                    </Box>
                }
                <TextField
                    type="number"
                    fullWidth
                    autoFocus
                    inputRef={provider.priceRef}
                    defaultValue={getProduct()?.price}
                    label="New Price"
                    placeholder="New Price"
                />
                <TextField
                    type="number"
                    sx={{
                        mt: 3
                    }}
                    fullWidth
                    autoFocus
                    inputRef={provider.upDownRef}
                    defaultValue={0}
                    label="Tăng/giảm"
                />
                <Stack direction='row' sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: 'end'
                }}>
                    <Button variant="outlined" onClick={() => provider.setOpenPrice(false)}>
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            ml: 2
                        }}
                        variant="contained"
                        onClick={_onOke}>
                        Ok
                    </Button>
                </Stack>
            </Container>
        </Modal>
    );
}

export default ModalUpdate