import { AppBar, Button, Container, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { RoutePath } from "../common/router/route-path";


interface MainPageType {

}

const MainPage = (props: MainPageType) => {
    const navigate = useNavigate()
    function _onLogout(): void {
        localStorage.removeItem('token')
        navigate(RoutePath.login)
    }

    return (
        <div>
            {/* <AppBar position="static">
                <Toolbar>

                    <Typography flexGrow={1}>Hub CMS</Typography>
                    <Button
                        onClick={_onLogout}
                        variant="text">
                        <img
                            src="https://cdn1.iconfinder.com/data/icons/heroicons-ui/24/logout-512.png"
                            width={32}
                            alt="" />
                    </Button>

                </Toolbar>
            </AppBar> */}
            <Outlet />
        </div>
    );
}

export default MainPage